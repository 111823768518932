import React from "react";
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignupForm from "../components/signupForm";
import Header from "../components/Header";
import "date-fns";

// Creating styled components using MUI v5 syntax
const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginLeft: "4%",
  marginRight: "4%",
  [theme.breakpoints.up("md")]: {
    marginTop: "138px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "95px",
  },
}));

const DataContainer = styled('div')({
  flexGrow: 1,
});

const GridRow = styled('div')(({ theme }) => ({
  ...theme.pageMargins,
}));

const PaperLeft = styled('div')(({ theme }) => ({
  textAlign: "left",
  color: theme.palette.text.primary,
  width: "100%",
  height: "570px",
  margin: 'auto',
}));

const PaperRight = styled('div')(({ theme }) => ({
  textAlign: "left",
  color: theme.palette.text.primary,
  width: "100%",
  height: "570px",
  margin: 'auto',
}));

const ColumnLeft = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  width: "45%",
  height: "570px",
  flexGrow: 1,
}));

const ColumnRight = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  width: "45%",
  height: "570px",
  flexGrow: 1,
}));

const CoreColumn = styled('div')({
  width: "100%",
  maxWidth: "400px",
  height: "570px",
  flexGrow: 1,
});

const DatePickerWrapper = styled('div')({
  marginTop: "16.5px",
});

const CustomButton = styled('button')({
  marginTop: "0px",
  width: "20px",
});

const debug = true;

export default function Signup() {
  return (
    <Root>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Header />
        <SignupForm />
      </Grid>
    </Root>
  );
}