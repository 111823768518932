import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Collapse,
  styled
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledPre = styled('pre')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflowX: 'auto',
  maxHeight: '400px',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '4px',
  },
}));

const DebugPanel = ({ formValues, contextData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Paper 
      sx={{ 
        mt: 4, 
        border: 1, 
        borderColor: 'grey.200' 
      }}
      elevation={0}
    >
      <Box 
        sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          bgcolor: 'grey.50',
          cursor: 'pointer'
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography variant="subtitle1" fontWeight="medium">
          Debug Panel
        </Typography>
        <IconButton size="small">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Form Values:
            </Typography>
            <StyledPre>
              {JSON.stringify(formValues, null, 2)}
            </StyledPre>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              User Context Data:
            </Typography>
            <StyledPre>
              {JSON.stringify(contextData, null, 2)}
            </StyledPre>
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

// Only show in development environment
const DEBUG_MODE = process.env.NODE_ENV === 'development';

export default DebugPanel;