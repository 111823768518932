import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { 
  SAFETY_OFFICER_DATES,
  OPENING_TIMES,
  isFirstSafetyOfficerDeadlinePassed,
  isSecondSafetyOfficerDeadlinePassed,
  formatTimeRange,
  SEASON_CONFIG
} from '../config/dates';

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
}));

export const DutyDetailsSection = ({ values, handleChange }) => {
  const eveningTimeRange = formatTimeRange(
    OPENING_TIMES.REGULAR.EVENING.START,
    OPENING_TIMES.REGULAR.EVENING.END
  );
  const lateSeasonTimeRange = formatTimeRange(
    OPENING_TIMES.REGULAR.EVENING.START,
    OPENING_TIMES.REGULAR.EVENING.LATE_SEASON_END
  );
  const weekendTimeRange = formatTimeRange(
    OPENING_TIMES.REGULAR.WEEKEND.START,
    OPENING_TIMES.REGULAR.WEEKEND.END
  );
  const holidayTimeRange = formatTimeRange(
    OPENING_TIMES.SCHOOL_HOLIDAYS.START,
    OPENING_TIMES.SCHOOL_HOLIDAYS.END
  );

  return (
    <FormSection>
      <SectionTitle variant="h5">Duty Details</SectionTitle>
      <Typography variant="body2" paragraph>
        {(isFirstSafetyOfficerDeadlinePassed() && isSecondSafetyOfficerDeadlinePassed()) ? (
          "All members will need to help out a couple of times a year as an administrator. " +
          "The registration period for safety officers has closed for this season."
        ) : (
          <>
            All members will need to help out a couple of times a year as 
            {!isFirstSafetyOfficerDeadlinePassed() && !isSecondSafetyOfficerDeadlinePassed() && " a safety officer or "}
            {!isFirstSafetyOfficerDeadlinePassed() && isSecondSafetyOfficerDeadlinePassed() && " a 1st safety officer or "}
            {isFirstSafetyOfficerDeadlinePassed() && !isSecondSafetyOfficerDeadlinePassed() && " a 2nd safety officer or "}
            an administrator.
            {!isFirstSafetyOfficerDeadlinePassed() && (
              " First safety officers receive a 2 day RLSS certified training course and in return " +
              `will get a ${SAFETY_OFFICER_DATES.FIRST_OFFICER.DISCOUNT_PERCENTAGE}% discount on the membership fee. ` +
              "The course for 1st safety officers will " +
              `run on ${SAFETY_OFFICER_DATES.FIRST_OFFICER.TRAINING_DATES.join(' and ')} ${SAFETY_OFFICER_DATES.FIRST_OFFICER.TRAINING_YEAR} for ` +
              "those who did not take the course last year. "
            )}
            {!isSecondSafetyOfficerDeadlinePassed() && (
              `2nd safety officers need to attend training on ${SAFETY_OFFICER_DATES.SECOND_OFFICER.TRAINING_DATES.map(d => d.DATE + ' at ' + d.TIME).join(' or ')} ` +
              `as well as a theory session on ${SAFETY_OFFICER_DATES.SECOND_OFFICER.THEORY_SESSION.DATE} at ${SAFETY_OFFICER_DATES.SECOND_OFFICER.THEORY_SESSION.TIME}.`
            )}
          </>
        )}
      </Typography>

      <FormControl component="fieldset">
        <FormLabel component="legend">This year I/we will help out as a:</FormLabel>
        <RadioGroup
          name="dutyType"
          value={values.dutyType}
          onChange={handleChange}
        >
          {!isFirstSafetyOfficerDeadlinePassed() && (
            <FormControlLabel 
              value="1st Safety Officer" 
              control={<Radio />} 
              label="1st Safety Officer" 
            />
          )}
          {!isSecondSafetyOfficerDeadlinePassed() && (
            <FormControlLabel 
              value="2nd Safety Officer" 
              control={<Radio />} 
              label="2nd Safety Officer" 
            />
          )}
          <FormControlLabel 
            value="Administrator" 
            control={<Radio />} 
            label="Administrator" 
          />
        </RadioGroup>
      </FormControl>

      {values.dutyType && (
        <Box mt={3}>
          {values.dutyType === "1st Safety Officer" && (
            <>
              <FormControl component="fieldset">
                <FormLabel component="legend">Did you complete the RLSS training course last year?</FormLabel>
                <RadioGroup
                  name="hadPriorTraining"
                  value={values.hadPriorTraining}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              {values.hadPriorTraining === "no" && (
                <FormControl component="fieldset" sx={{ mt: 2 }}>
                  <FormLabel component="legend">
                    Please indicate which training weekends you can attend (select all that apply):
                  </FormLabel>
                  <FormGroup>
                    {SAFETY_OFFICER_DATES.FIRST_OFFICER.TRAINING_DATES.map((date, index) => (
                      <FormControlLabel
                        key={date}
                        control={
                          <Checkbox
                            checked={values[`dutydate${date.replace(/\s+/g, '')}`]}
                            onChange={handleChange}
                            name={`dutydate${date.replace(/\s+/g, '')}`}
                          />
                        }
                        label={date}
                      />
                    ))}
                  </FormGroup>
                  <FormHelperText>You must be able to attend both days for the training</FormHelperText>
                </FormControl>
              )}
            </>
          )}

          {values.dutyType === "2nd Safety Officer" && (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                I will attend the second safety officer course on:
              </FormLabel>
              <RadioGroup
                name="dutydateSecond"
                value={values.dutydateSecond}
                onChange={handleChange}
              >
                {SAFETY_OFFICER_DATES.SECOND_OFFICER.TRAINING_DATES.map((dateObj) => (
                  <FormControlLabel
                    key={dateObj.DATE}
                    value={`${dateObj.DATE} at ${dateObj.TIME}`}
                    control={<Radio />}
                    label={`${dateObj.DATE} at ${dateObj.TIME}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          <Box mt={3}>
            <FormLabel component="legend">
              On which days you can help out as a{values.dutyType === "Administrator" && "n"} {values.dutyType}?
            </FormLabel>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyMon}
                        onChange={handleChange}
                        name="dutyMon"
                      />
                    }
                    label={`Monday ${eveningTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyTue}
                        onChange={handleChange}
                        name="dutyTue"
                      />
                    }
                    label={`Tuesday (Lane swimming) ${eveningTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyWed}
                        onChange={handleChange}
                        name="dutyWed"
                      />
                    }
                    label={`Wednesday ${eveningTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyFri}
                        onChange={handleChange}
                        name="dutyFri"
                      />
                    }
                    label={`Friday ${eveningTimeRange}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutySat}
                        onChange={handleChange}
                        name="dutySat"
                      />
                    }
                    label={`Saturday ${weekendTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutySun}
                        onChange={handleChange}
                        name="dutySun"
                      />
                    }
                    label={`Sunday ${weekendTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyHol1}
                        onChange={handleChange}
                        name="dutyHol1"
                      />
                    }
                    label={`School holidays Tuesday ${holidayTimeRange}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.dutyHol2}
                        onChange={handleChange}
                        name="dutyHol2"
                      />
                    }
                    label={`School holidays Thursday ${holidayTimeRange}`}
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormHelperText>
              Please note that from {SEASON_CONFIG.EVENING_TIMES_CHANGE_DATE} the evening opening times change to {lateSeasonTimeRange}.
            </FormHelperText>
          </Box>
        </Box>
      )}
    </FormSection>
  );
};

export default DutyDetailsSection;