import React from 'react';
import { Helmet } from 'react-helmet';
import AppComponent from '../components/component';
import {
  SEASON_CONFIG,
  OPENING_TIMES,
  formatTimeRange,
  getSessionEndTime
} from '../config/dates';
import './home.css';

const Home = () => {
  const currentYear = new Date().getFullYear();
  const regularEveningTime = formatTimeRange(
    OPENING_TIMES.REGULAR.EVENING.START,
    OPENING_TIMES.REGULAR.EVENING.END
  );
  const lateSeasonTime = formatTimeRange(
    OPENING_TIMES.REGULAR.EVENING.START,
    OPENING_TIMES.REGULAR.EVENING.LATE_SEASON_END
  );
  const weekendTime = formatTimeRange(
    OPENING_TIMES.REGULAR.WEEKEND.START,
    OPENING_TIMES.REGULAR.WEEKEND.END
  );
  const holidayTime = formatTimeRange(
    OPENING_TIMES.SCHOOL_HOLIDAYS.START,
    OPENING_TIMES.SCHOOL_HOLIDAYS.END
  );

  return (
    <div className="home-container10">
      <Helmet>
        <title>Comberton Community Swimming Club</title>
        <meta property="og:title" content="Comberton Community Swimming Club" />
      </Helmet>
      <div className="home-header">
        <span className="home-text10">CCSC</span>
      </div>
      <div className="home-container11">
        <img
          alt="image"
          src="https://play.teleporthq.io/static/svg/default-img.svg"
          className="home-image1"
        />
        <div className="home-container12">
          <div className="home-container13">
            <span className="home-text11">
              <span>Comberton Community</span>
              <br></br>
              <span>Swimming Club</span>
            </span>
            <span className="home-text15">A pool for and run by members</span>
      {/*          <a href="/register" color="Black" className="home-button">
              Become a member
            </a>
            <a href="/register" className="home-button2 Button2">
              Become a member
            </a> */}
          </div>
        </div>
      </div>
      <div className="home-container14">
        <div className="home-container15">
          <span className="home-text16">Season {SEASON_CONFIG.REGISTRATION_YEAR}</span>
          <span className="home-text17">
            <span>{new Date(SEASON_CONFIG.SEASON_START).toLocaleDateString('en-GB', { day: 'numeric', month: 'long' })} - {new Date(SEASON_CONFIG.SEASON_END).toLocaleDateString('en-GB', { day: 'numeric', month: 'long' })}*</span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="home-container16">
        <div className="home-container17"></div>
        <div className="home-container18">
          <span className="home-text20">
            *We may extend the season by a few weeks if the weather allows and
            we have enough members who are prepared to do additional duties.
          </span>
        </div>
      </div>
      <div className="home-container19">
        <div className="home-container20">
          <AppComponent
            imageSrc="/pocket%20watch-200h.png"
            infoTitle2="Opening Times"
          />
          <span className="home-infobody1">
            <span className="home-text21">
              Monday, Tuesday*, Wednesday & Friday{' '}
            </span>
            <span className="home-text22">{regularEveningTime}</span>
            <span className="home-text23">.</span>
            <br className="home-text24"></br>
            <span className="home-text25">Saturday & Sunday </span>
            <span className="home-text26">{weekendTime}</span>
            <span className="home-text27">.</span>
            <br className="home-text28"></br>
            <span className="home-text29">
              *Tuesdays are for lane-swimming only.
            </span>
          </span>
        </div>
        <div className="home-container21">
          <AppComponent
            imageSrc="/owl-200h.png"
            infoTitle2="School Holidays"
          />
          <span className="home-infobody2">
            <span className="home-text30">
              During the school holidays we run two extra sessions on{' '}
            </span>
            <br className="home-text31"></br>
            <span className="home-text32">Tuesday & Thursday </span>
            <span className="home-text33">{holidayTime}.</span>
          </span>
        </div>
        <div className="home-container22">
          <AppComponent
            imageSrc="/sunset-200h.png"
            infoTitle2="Daylight Adjustments"
          />
          <span className="home-infobody3">
            <span className="home-text34">
              From {new Date(SEASON_CONFIG.EVENING_TIMES_CHANGE_DATE).toLocaleDateString('en-GB', { day: 'numeric', month: 'long' })}, 
              when it gets darker in the evenings, we change the opening times to{' '}
            </span>
            <span className="home-text35">{lateSeasonTime}.</span>
          </span>
        </div>
      </div>

      {/* Rest of the component remains the same */}
      <div className="home-container32">
        <span className="home-text74 ParagraphRegular">
          © Comberton Community Swimming Club, {currentYear}.
        </span>
      </div>
    </div>
  );
};

export default Home;