import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Divider,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { UnavailabilityCalendar } from './UnavailabilityCalendar';
import { DutyDetailsSection } from './DutyDetailsSection'; 
import { HouseholdMembersSection } from './HouseholdMembersSection';
import { 
  SEASON_CONFIG,
  isFirstSafetyOfficerDeadlinePassed,
  isSecondSafetyOfficerDeadlinePassed,
  getDefaultDutyType
} from '../config/dates';
import  DebugPanel from '../components/DebugPanel';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
}));

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const MembershipForm = () => {
  const { userData, updateMultipleFields } = useUser();
  const navigate = useNavigate();
  
  // Create debounced update function
  const debouncedUpdate = React.useCallback(
    _.debounce((newValues) => {
      updateMultipleFields(newValues);
    }, 500),
    [] // Empty dependency array since we don't want to recreate the debounced function
  );

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const validationSchema = yup.object({
    unavailableDates: yup.array().of(yup.string()),
    contactEmail: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    firstName: yup
      .string("Enter your first name")
      .min(2, "Your first name should have a minimum of 2 characters")
      .required("First name is required"),
    lastName: yup
      .string("Enter your last name")
      .min(2, "Your last name should have a minimum of 2 characters")
      .required("Last name is required"),
    phoneNumber: yup
      .string("Please enter a phone number")
      .required("Phone number is Required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
    dutyType: yup
      .string()
      .required("Duty type is required")
      .test("valid-duty-type", "Invalid duty type selected", function(value) {
        if (isFirstSafetyOfficerDeadlinePassed() && value === "1st Safety Officer") {
          return false;
        }
        if (isSecondSafetyOfficerDeadlinePassed() && value === "2nd Safety Officer") {
          return false;
        }
        return true;
      }),
    tandcAcceptance: yup
      .boolean()
      .oneOf([true], "Members must accept the terms and conditions"),
  });

  const payMoney = async (values, { setSubmitting }) => {
    try {
      // Final submission - values are already in sync with context
      navigate('/donation');
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom>
          {SEASON_CONFIG.REGISTRATION_YEAR} Membership Registration {SEASON_CONFIG.REGISTRATION_VERSION}
        </Typography>
        
        <Formik
          initialValues={{
            ...userData,
            familyMembers: userData.familyMembers || [],
            dutyType: getDefaultDutyType(userData.dutyType)
          }}
          validationSchema={validationSchema}
          onSubmit={payMoney}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => {
            // Update user context whenever form values change
            React.useEffect(() => {
              debouncedUpdate(values);
            }, [values, debouncedUpdate]);



    return (
      <Form>
              {/* Contact Details Section */}
              <FormSection>
                <SectionTitle variant="h5">Contact Details</SectionTitle>
                <Typography variant="body2" gutterBottom>
                  Please enter the name and details of the person who will help out as an admin or safety officer.
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      name="contactEmail"
                      label="Email"
                      variant="outlined"
                      value={values.contactEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.contactEmail && Boolean(errors.contactEmail)}
                      helperText={touched.contactEmail && errors.contactEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      name="address"
                      label="Address incl. postcode"
                      variant="outlined"
                      multiline
                      value={values.address}
                      onChange={handleChange}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      name="phoneNumber"
                      label="Phone Number"
                      variant="outlined"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.rotaPublish}
                          onChange={handleChange}
                          name="rotaPublish"
                        />
                      }
                      label="Please include my email address on the rota so others can contact me for duty swaps."
                    />
                  </Grid>
                </Grid>
              </FormSection>

              <Divider />

                {/* Household Members Section */}
                <FormSection>
                  <HouseholdMembersSection 
                    values={values} 
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </FormSection>

              <Divider />

         {/* Duty Details Section */}
                <DutyDetailsSection values={values} handleChange={handleChange} />


              {/* Holiday Periods Section */}
              <FormSection>
                <SectionTitle variant="h5">Periods of Unavailability</SectionTitle>
                <Typography variant="body2" gutterBottom>
                  Please select the dates when you are not able to help out as a{values.dutyType === "Administrator" && "n"} {values.dutyType}
                </Typography>
                <UnavailabilityCalendar 
                  selectedDates={values.unavailableDates || []}
                  onChange={(dates) => {
                    handleChange({
                      target: {
                        name: 'unavailableDates',
                        value: dates
                      }
                    });
                  }}
                  seasonStart="2025-04-01"  /* Adjust these dates as needed */
                  seasonEnd="2025-08-31"
                />
              </FormSection>

              <Divider />

              {/* Terms and Conditions Section */}
              <FormSection>
                <SectionTitle variant="h5">Declaration</SectionTitle>
                <Typography variant="body2" paragraph>
                  The Swimming Pool Committee does not accept any responsibility for loss or damage to any property 
                  left in the pool area or losses consequent upon such loss or damage, or for injury or death of 
                  any person except directly caused through the negligence of the swimming Pool Committee{" "}
                  <a href="CCSC_Rules_book.pdf" target="_blank" rel="noopener noreferrer">
                    (Rule 19 Members Rulebook)
                  </a>
                </Typography>

                <Typography variant="body2" paragraph>
                  DECLARATION: I agree with the terms and Conditions set out above and will abide by the Rules of the 
                  Club as set out in the{" "}
                  <a href="CCSC_Rules_book.pdf" target="_blank" rel="noopener noreferrer">
                    Members Rulebook
                  </a>{" "}
                  (clicking the link opens the Rulebook in a new tab/window).
                </Typography>

                <Typography variant="body2" paragraph>
                  In becoming a CCSC member I hereby agree to be bound by the terms of the Data Protection Act 1998 
                  (as amended by the General Data Protection Regulations 2018) in respect of not sharing or disclosing 
                  any personal data of CCSC Members or associates outside of CCSC without their prior consent. I further 
                  agree to CCSC using my personal data (including my name and relevant contact details) to share with 
                  other CCSC members for administrative purposes; (e.g. Admin / Safety Officer Duty rosters and amendments). 
                  CCSC for its part will ensure the confidentiality and privacy of all personal data it holds on behalf 
                  of its Members.
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      checked={values.tandcAcceptance}
                      onChange={handleChange}
                      name="tandcAcceptance"
                    />
                  }
                  label="I accept the terms and conditions"
                />
                {touched.tandcAcceptance && errors.tandcAcceptance && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {errors.tandcAcceptance}
                  </Alert>
                )}
              </FormSection>

              {/* Error Messages */}
              {Object.keys(errors).length > 0 && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="h6">Please correct the following errors:</Typography>
                  {Object.entries(errors).map(([key, value]) => (
                    <Typography key={key}>- {value}</Typography>
                  ))}
                </Alert>
              )}

              {/* Submit Button */}
              <Box sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!isValid || Object.keys(errors).length > 0}
                  sx={{ minWidth: 200 }}
                >
                  Submit Registration
                </Button>
              </Box>
                {/* Debug Panel */}
                {process.env.NODE_ENV === 'development' && (
                  <DebugPanel formValues={values} contextData={userData} />
                )}
              </Form>
    );
  }}
</Formik>
      </StyledPaper>
    </Container>
  );
};

export default MembershipForm;