import React from 'react';

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-blue-600 text-white">
      <div className="mx-auto px-8 py-4">
        <div className="flex items-center">
          <a href="/" className="text-xl font-semibold hover:text-blue-100">
            CCSC
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;