// src/context/UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    contactEmail: "",
    address: "",
    phoneNumber: "",
    rotaPublish: true,
    familyMembers: [],
    dutyType: "1st Safety Officer",
    dutyMon: false,
    dutyTue: false,
    dutyWed: false,
    dutyFri: false,
    dutySat: false,
    dutySun: false,
    dutyHol1: false,
    dutyHol2: false,
    dutydateSecond: "22nd of April at 13:30",
    holidaysPeriods: [],
    moreDuties: "",
    tandcAcceptance: false,
    comments: "",
    donation: "0",
    hadPriorTraining: "no"
  });

  // Update a single field
  const updateField = (fieldName, value) => {
    setUserData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  // Update multiple fields at once
  const updateMultipleFields = (updates) => {
    setUserData(prevData => ({
      ...prevData,
      ...updates
    }));
  };

  // Reset all data
  const resetData = () => {
    setUserData({
      firstName: "",
      lastName: "",
      contactEmail: "",
      address: "",
      phoneNumber: "",
      rotaPublish: true,
      familyMembers: [],
      dutyType: "1st Safety Officer",
      dutyMon: false,
      dutyTue: false,
      dutyWed: false,
      dutyFri: false,
      dutySat: false,
      dutySun: false,
      dutyHol1: false,
      dutyHol2: false,
      dutydateSecond: "22nd of April at 13:30",
      holidaysPeriods: [],
      moreDuties: "",
      tandcAcceptance: false,
      comments: "",
      donation: "0",
      unavailableDates: []
    });
  };

  const value = {
    userData,
    updateField,
    updateMultipleFields,
    resetData
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

// Custom hook for using the context
export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}