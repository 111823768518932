import React from 'react'

import PropTypes from 'prop-types'

import './component.css'

const AppComponent = (props) => {
  return (
    <div className="component-container1">
      <div className="component-container2">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          loading="lazy"
          className="component-image"
        />
      </div>
      <span className="component-text">{props.infoTitle2}</span>
    </div>
  )
}

AppComponent.defaultProps = {
  infoTitle2: 'Regular Times',
  imageAlt: 'image',
  imageSrc: '/pocket%20watch-200h.png',
}

AppComponent.propTypes = {
  infoTitle2: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default AppComponent
