import React from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Typography, IconButton, Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import DeleteIcon from '@mui/icons-material/Delete';
import formatDate from 'date-fns/format';
import { parseISO, isSameDay, isAfter, isBefore } from 'date-fns';

export const UnavailabilityCalendar = ({ 
  selectedDates = [], 
  onChange,
  seasonStart = "2024-04-01", 
  seasonEnd = "2024-08-31"
}) => {
  const handleDateChange = (newDate) => {
    if (!newDate) return;
    
    const formattedDate = formatDate(newDate, 'yyyy-MM-dd');
    if (selectedDates.includes(formattedDate)) {
      onChange(selectedDates.filter(date => date !== formattedDate));
    } else {
      onChange([...selectedDates, formattedDate].sort());
    }
  };

  const handleDelete = (dateToDelete) => {
    onChange(selectedDates.filter(date => date !== dateToDelete));
  };

  const renderDates = () => {
    if (!selectedDates || selectedDates.length === 0) {
      return (
        <Typography color="textSecondary" variant="body2" sx={{ p: 2 }}>
          No dates selected
        </Typography>
      );
    }

    return (
      <List dense sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        {selectedDates.map((date) => (
          <ListItem key={date} sx={{ py: 0 }}>
            <ListItemText 
              primary={formatDate(parseISO(date), 'EEE, d MMM')}
            />
            <ListItemSecondaryAction>
              <IconButton 
                edge="end" 
                aria-label="delete" 
                size="small"
                onClick={() => handleDelete(date)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  const CustomDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    
    const start = parseISO(seasonStart);
    const end = parseISO(seasonEnd);
    
    const isDisabled = isBefore(day, start) || isAfter(day, end);
    const isSelected = selectedDates.some(date => isSameDay(parseISO(date), day));

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        selected={isSelected}
        disabled={isDisabled}
      />
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            defaultValue={parseISO(seasonStart)}
            onChange={handleDateChange}
            slots={{
              day: CustomDay
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper 
          sx={{ 
            bgcolor: 'grey.50',
            height: { xs: 'auto', md: '320px' },
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography variant="subtitle1" sx={{ p: 2, pb: 1 }}>
            Selected Dates ({selectedDates.length})
          </Typography>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {renderDates()}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};