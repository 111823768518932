// src/components/DonationForm.js
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useUser } from '../context/UserContext';
import * as Yup from 'yup';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import DebugPanel from '../components/DebugPanel';

const DonationSchema = Yup.object().shape({
  donationType: Yup.string().required('Please select a donation amount'),
  customAmount: Yup.string().when('donationType', {
    is: 'other',
    then: () => Yup.string()
      .required('Please enter a custom amount')
      .matches(/^\d+$/, 'Please enter a valid number'),
    otherwise: () => Yup.string()
  })
});

const presetAmounts = [
  { label: 'Do not add a donation', value: '0' },
  { label: '£5', value: '5' },
  { label: '£15', value: '15' },
  { label: '£30', value: '30' },
  { label: '£50', value: '50' },
  { label: 'Other', value: 'other' }
];

const WEBHOOK_URL = 'https://james.akendi.co.uk/webhook/8e626ff6-7b62-4bff-84bd-8e4e4291f679';
const DATE_X = new Date('2025-01-01'); // Replace with actual date X

function DonationForm() {
  const { userData, updateField } = useUser();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const calculateMembershipCost = () => {
    const currentDate = new Date();
    const hasFamily = userData.familyMembers && userData.familyMembers.length > 0;
    const is1stSafetyOfficer = userData.dutyType === '1st Safety Officer';
    
    let baseCost;
    if (hasFamily) {
      baseCost = currentDate < DATE_X ? 55 : 70;
    } else {
      baseCost = currentDate < DATE_X ? 40 : 55;
    }
    
    return is1stSafetyOfficer ? baseCost / 2 : baseCost;
  };

  const membershipCost = calculateMembershipCost();
  const showDiscountText = userData.dutyType === '1st Safety Officer';

  const getCurrentDonationType = (currentDonation) => {
    const presetValue = presetAmounts.find(preset => preset.value === currentDonation);
    return presetValue ? presetValue.value : 'other';
  };

  const initialDonationType = getCurrentDonationType(userData.donation);

  const handleSubmitToWebhook = async (values, setSubmitting) => {
    try {
      const finalAmount = values.donationType === 'other' ? values.customAmount : values.donationType;
      updateField('donation', finalAmount);

      const response = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...userData,
          donation: finalAmount
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { url } = await response.json();
      window.location.href = url;

      setSnackbar({
        open: true,
        message: 'Successfully submitted donation',
        severity: 'success'
      });

    } catch (error) {
      console.error('Submission error:', error);
      setSnackbar({
        open: true,
        message: 'Error submitting donation. Please try again.',
        severity: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        maxWidth: 400, 
        mx: 'auto', 
        p: 4 
      }}
    >
      <Typography 
        variant="h5" 
        component="h2" 
        align="center" 
        gutterBottom 
        sx={{ mb: 2 }}
      >
        Add a Donation?
      </Typography>

      <Typography 
        variant="body1" 
        align="center" 
        sx={{ mb: 4 }}
      >
        Your membership for 2025 is £{membershipCost}
        {showDiscountText && ' (including 50% 1st Safety Officer discount)'}
        . Would you like to add a donation?
      </Typography>
      
      <Formik
        initialValues={{
          donationType: initialDonationType,
          customAmount: initialDonationType === 'other' ? userData.donation : ''
        }}
        validationSchema={DonationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitToWebhook(values, setSubmitting);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <FormControl component="fieldset" fullWidth error={touched.donationType && !!errors.donationType}>
              <RadioGroup
                name="donationType"
                value={values.donationType}
                onChange={handleChange}
              >
                {presetAmounts.map((amount) => (
                  <FormControlLabel
                    key={amount.value}
                    value={amount.value}
                    control={<Radio />}
                    label={amount.label}
                    sx={{ mb: 1 }}
                  />
                ))}
              </RadioGroup>
              {touched.donationType && errors.donationType && (
                <FormHelperText>{errors.donationType}</FormHelperText>
              )}
            </FormControl>

            {values.donationType === 'other' && (
              <Box sx={{ mt: 2, mb: 3 }}>
                <TextField
                  fullWidth
                  id="customAmount"
                  name="customAmount"
                  label="Custom Amount"
                  InputProps={{
                    startAdornment: '£',
                  }}
                  value={values.customAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.customAmount && !!errors.customAmount}
                  helperText={touched.customAmount && errors.customAmount}
                  variant="outlined"
                />
              </Box>
            )}

            <Box sx={{ mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                disabled={isSubmitting}
                sx={{ 
                  py: 1.5,
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                {isSubmitting ? 'Processing...' : 'Continue to Payment'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
                      {/* Debug Panel */}
                      {process.env.NODE_ENV === 'development' && (
                  <DebugPanel contextData={userData} />
                )}
    </Paper>
  );
}

export default DonationForm;